



































































































































.layout-padding
  max-width 768px
  position relative

.q-page
  position relative

.vertical-rule
  max-width 1px !important
  margin-top 27px
  margin-bottom 7px
  background #c6c7c4

.horizontal-rule
  margin-left 8px
  margin-right 8px
  padding-bottom 5px
  border-bottom 1px solid #c6c7c4

.loading
  background rgba(244,244,244, .5)
  position absolute
  top 0
  right 150px
  bottom 0
  left 150px
  svg
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

.buy-tickets
  border-radius 5px
  border none
  padding 5px
  width 100%
  height 50px
  text-transform uppercase
  font-weight 700
