
















































































































































.q-card
  min-height 120px
  position relative
  background-color #fff

.journey-info:hover
  text-decoration underline

.modal-body
  min-width 500px

.segment
  font-size 1.4em
  margin-top 8px
.lineName
  font-size 1.4em
.line
  position relative
  margin: 0 8px
  height: 1px
  background-color currentColor
  &:before,
  &:after
    content ""
    position absolute
    width 10px
    height 10px
    background currentColor
    border-radius 100%
    top -5px
  &:after
    right 0

.journey-leg
  position relative
  border-top 1px dashed lightgray
  padding-bottom 15px
  padding-top 15px

  h6
    margin-top 0

.journey-leg:first-child
  border-top 0

.point, .way
  background #1976d2

.point
  width 20px
  height 20px
  border-radius 100%
  top -5px

.way
  height 60px
  width 2px
  margin-left 9px

.to
  position absolute
  bottom 5px

sub
  display block

.spacer
  height 62px

.selected
  background-color light-info

